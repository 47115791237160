import React, { Component } from "react"
import { apiURLAndamento, apiURLEncerrados } from '../services/api'

export class JogosAndamento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        await fetch(apiURLAndamento + this.props.dataJogos)
            .then(resp => resp.json())
            .then(data => this.setState({
                ...this.state,
                datas: data,
            }))
    }

    render() {

        function TabelaJogosAndamento(props) {
            return props.jogosData.map(function (dados, i) {
                var fase = dados.fase
                var hora = dados.hora.split(":")
                let horario = `${hora[0]}:${hora[1]}`
                var item = dados
                var texto
                if (fase === 'final') {
                    texto = <div style={{ color: "#FFD700", fontWeight: 'bolder', fontSize: '40px' }} className="col-md-12">FINAL</div>
                }
                else if (fase === '3lugar') {
                    texto = <div style={{ color: "#D4AF37", fontWeight: 'bolder', fontSize: '30px' }} className="col-md-12">3º Lugar</div>
                }
                return (
                    <div key={i} className="col-md-12 text-center row mt-2">
                        <div className="col-md-4 text-center"></div>
                        <div className='col-md-4' >
                            {texto}
                            <img width="60px" height="60px" src={item.logoA} alt={item.TimeA} />
                            <span style={{ fontSize: "x-large", fontWeight: "bold", marginRight: "20px", marginLeft: "20px" }}>
                                {horario}
                            </span>
                            <img width="60px" height="60px" src={item.logoB} alt={item.TimeB} />
                        </div >
                        <div className="col-md-4"></div>
                    </div>
                )
            })
        }
        return (
            <div>
                <TabelaJogosAndamento jogosData={this.state.datas} />
            </div>

        )
    }
}

export class JogosEncerrados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
        }
    }

    componentDidMount() {
        this.getData();
    }
    getData = async () => {
        await fetch(apiURLEncerrados + this.props.dataJogos)
            .then(resp => resp.json())
            .then(data => this.setState({
                ...this.state,
                datas: data,
            }))
    }

    render() {

        function TabelaJogosEncerrados(props) {
            return props.jogosData.map(function (dados, i) {

                var item = dados; var styleA; var styleB;
                if (item.resA > item.resB) { styleA = { fontWeight: "bolder" }; styleB = {} }
                else if (item.resA < item.resB) { styleB = { fontWeight: "bolder" }; styleA = {} }
                return (
                    <div key={i} className="col-md-12 text-center row mt-2">
                        <div className="col-md-4"></div>
                        <div className='col-md-4' >
                            <img width="60px" height="60px" src={item.logoA} alt={item.TimeA} />
                            <span className="results" style={{
                                fontSize: "x-large",
                                marginRight: "5px", marginLeft: "5px"
                            }}>
                                <span className="results"> <span style={styleA}>{item.resA}</span> X <span style={styleB}>{item.resB}</span>  </span>
                            </span>
                            <img width="60px" height="60px" src={item.logoB} alt={item.TimeB} />
                        </div >
                        <div className="col-md-4"></div>
                    </div>
                )
            })

        }
        return (
            <div>
                <TabelaJogosEncerrados jogosData={this.state.datas} />
            </div >

        )
    }
}