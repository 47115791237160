// App.js
import React, { Component } from 'react';
import Header from '../components/Header';
// import SideBar from '../components/SideBar';
import Content from './Content';
import { getEquipeID, getGruposEquipeID, getJogadoresEquipeID, getJogosAndEquipeID, getJogosEncEquipeID } from '../services/dados'

class Equipes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dadosEquipe: [],
            dadosGrupos: [],
            dadosJogadores: [],
            dadosJogosAndamento: [],
            dadosJogosEncerrados: []
        };
        this.getEquipe = this.getEquipe.bind(this);
    }

    getEquipe(user) {
        getEquipeID(user).then(response => {
            this.setState({ dadosEquipe: response.data });
        });
        getGruposEquipeID(user).then(response => {
            this.setState({ dadosGrupos: response.data })
        })
        getJogadoresEquipeID(user).then(response => {
            this.setState({ dadosJogadores: response.data })
        })
        getJogosAndEquipeID(user).then(response => {
            this.setState({ dadosJogosAndamento: response.data })
        })
        getJogosEncEquipeID(user).then(response => {
            this.setState({ dadosJogosEncerrados: response.data })
        })
    }
    componentDidMount() {
        this.getEquipe(this.props.match.params.id);
    }
    render() {
        return (
            <div>
                <Header dados={this.state.dadosEquipe} />
                {/* <SideBar dados={this.state.dadosEquipe} /> */}
                <Content
                    tabela={this.props.location.state}
                    result={this.state.dadosEquipe}
                    grupos={this.state.dadosGrupos}
                    jogadores={this.state.dadosJogadores}
                    jogosAndamento={this.state.dadosJogosAndamento}
                    jogosEncerrados={this.state.dadosJogosEncerrados}
                />
            </div>
        );
    }
}

export default Equipes;