import axios from "axios";
//export var apiURL = "http://localhost:8080";
export var apiURL = "https://api.andrewisley.com.br"
export var ambiente = 'prod'
const api = axios.create({
  baseURL: apiURL
});

export const apiURLAndamento = apiURL + "/jogos/andamento/"
export const apiURLEncerrados = apiURL + "/jogos/encerrados/"

export default api;
