// SideBar.js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SideBar extends Component {

    render() {
        const nome_abrev = this.props.dados.nome;
        const foto = this.props.dados.logotipo
        return (
            <aside className="main-sidebar elevation-4 sidebar-dark-indigo">
                <Link to="index3.html" className="brand-link navbar-indigo">
                    <img src="./images/logo.png" alt="AdminLTE" className="brand-image img-circle elevation-3"
                        style={{ opacity: 0.8 }} />
                    <span className="brand-text font-weight-light">Copa Gospel</span>
                </Link>


                <div className="sidebar">

                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src={foto} className="img-circle elevation-2" alt="user" />
                        </div>
                        <div className="info">
                            <Link to="/" className="d-block">{nome_abrev}</Link>
                        </div>
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item has-treeview menu-open">
                                <Link to="/" className="nav-link active">
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        Dashboard
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview">
                                <Link to="/" className="nav-link">
                                    <i className="nav-icon fas fa-chart-pie"></i>
                                    <p>
                                        Charts
                <i className="right fas fa-angle-left"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pages/charts/chartjs.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>ChartJS</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/charts/flot.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Flot</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/charts/inline.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Inline</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item has-treeview">
                                <Link to="/" className="nav-link">
                                    <i className="nav-icon fas fa-tree"></i>
                                    <p>
                                        UI Elements
                <i className="fas fa-angle-left right"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pages/UI/general.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>General</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/icons.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Icons</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/buttons.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Buttons</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/sliders.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Sliders</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/modals.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Modals & Alerts</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/navbar.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Navbar & Tabs</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/timeline.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Timeline</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pages/UI/ribbons.html" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Ribbons</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-header">MISCELLANEOUS</li>
                            <li className="nav-item">
                                <Link to="https://adminlte.io/docs/3.0" className="nav-link">
                                    <i className="nav-icon fas fa-file"></i>
                                    <p>Documentation</p>
                                </Link>
                            </li>
                            <li className="nav-header">LABELS</li>
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                    <i className="nav-icon far fa-circle text-danger"></i>
                                    <p className="text">Important</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                    <i className="nav-icon far fa-circle text-warning"></i>
                                    <p>Warning</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                    <i className="nav-icon far fa-circle text-info"></i>
                                    <p>Informational</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }
}