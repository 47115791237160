import React, { Component } from 'react';
import { format } from 'date-fns';
import { Link } from "react-router-dom"

export function formatReal(int) {
    var tmp = int + "";
    tmp = tmp.split(".");
    return (tmp[0] + "%");
}

export default class Content extends Component {

    render() {
        function Jogadores(props) {
            return props.dadosJogadores.map(function (item, i) {
                if (item.foto === null) {
                    item.foto = './images/jogadores/semFoto.png'
                }
                var foto = item.foto
                return (

                    <div key={i} className="col-md-4 text-center">
                        <Link className="pageAtleta"
                            to={{
                                pathname: `/Atletas/${item.Id}`,
                                state: { lugar: lugar.lugar }
                            }}
                        >
                            <img key={item.nome} alt="atleta" width="100" height="100" src={foto} />
                            <br />{item.nome}
                            <br /><b>{item.posicao}</b>
                        </Link>
                    </div >

                );
            });
        }
        function ProxJogos(props) {
            return props.proxJogos.map(function (item, i) {
                const data = format(new Date(item.data), 'dd/MM/yyyy');
                const [horas, minutos] = item.hora.split(":");
                var logoA = item.logoA
                var newLogoA = logoA.split(".")
                var logoB = item.logoB
                var newLogoB = logoB.split(".")
                return (
                    <div key={i}>
                        <div className='text-center mr-3 mt-3'>
                            <span>  {data}  </span>
                            <br />
                            <img key={item.TimeA} width="85" height="85" alt="equipeA" src={`${newLogoA[0]}.${newLogoA[1]}`} />
                            <span className="pJogos">  {horas}:{minutos}  </span>
                            <img key={item.TimeB} width="85" height="85" alt="equipeB" src={`${newLogoB[0]}.${newLogoB[1]}`} />
                        </div>
                    </div>
                );
            });
        }
        function AntJogos(props) {
            return props.antJogos.map(function (item, i) {
                var styleA; var styleB;
                const data = format(new Date(item.data), 'dd/MM/yyyy');
                if (item.resA > item.resB) { styleA = { fontWeight: "bolder" }; styleB = {} }
                else if (item.resA < item.resB) { styleB = { fontWeight: "bolder" }; styleA = {} }
                var logoA = item.logoA
                var newLogoA = logoA.split(".")
                var logoB = item.logoB
                var newLogoB = logoB.split(".")
                return (
                    <div key={i}>
                        <div className='text-center mr-3 mt-3'>
                            <span>  {data}  </span>
                            <br />
                            <img key={item.TimeA} width="85" height="85" alt="equipeA" src={`${newLogoA[0]}.${newLogoA[1]}`} />
                            <span className="results"> <span style={styleA}>{item.resA}</span> X <span style={styleB}>{item.resB}</span>  </span>
                            <img key={item.TimeB} width="85" height="85" alt="equipeB" src={`${newLogoB[0]}.${newLogoB[1]}`} />
                        </div>
                    </div>
                );
            });
        }
        const dados = this.props.grupos;
        const equipe = this.props.result;
        const dadosJogadoresProps = this.props.jogadores
        const jogosAndamentoProps = this.props.jogosAndamento
        const jogosEncerradosProps = this.props.jogosEncerrados
        const logotipo = equipe.logotipo
        var lugar
        if (this.props.tabela === null || this.props.tabela === undefined) { lugar = '0' } else { lugar = this.props.tabela }
        var aprov = dados.aproveitamento
        var pattern = (formatReal(aprov))
        return (
            <div className="content-wrapper" >
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Estatísticas</h3>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="text-center">
                                                <strong><img src={logotipo} width="50px" height="50px" alt='equipe' /> {equipe.nome}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-8">
                                            <table id="example" className="table display nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Equipe</th>
                                                        <th>P</th>
                                                        <th>J</th>
                                                        <th>V</th>
                                                        <th>E</th>
                                                        <th>D</th>
                                                        <th>GP</th>
                                                        <th>GC</th>
                                                        <th>SG</th>
                                                        <th>%</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>{lugar.lugar} <img src={logotipo} width="30px" height="30px" alt='equipe' /> {equipe.nome}</td>
                                                        <td>{dados.jogos}</td>
                                                        <td>{dados.pontos}</td>
                                                        <td>{dados.vitoria}</td>
                                                        <td>{dados.derrota}</td>
                                                        <td>{dados.empate}</td>
                                                        <td>{dados.golsPro}</td>
                                                        <td>{dados.golsCont}</td>
                                                        <td>{dados.saldoGols}</td>
                                                        <td>{pattern}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Proximos Jogos</h3>
                                        </div>
                                        <div className="col-sm-12 col-xs-8">
                                            <ProxJogos proxJogos={jogosAndamentoProps} />
                                        </div>
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Jogos Anteriores</h3>
                                        </div>
                                        <div className="col-sm-12 col-xs-8">
                                            <AntJogos antJogos={jogosEncerradosProps} />
                                        </div>
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Jogadores</h3>
                                        </div>
                                        <div className="col-sm-12 col-xs-8 row">
                                            <Jogadores dadosJogadores={dadosJogadoresProps} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        )
    }
}