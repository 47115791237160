// App.js
import React, { Component } from 'react';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import Content from './Content';
import { getUsers } from '../services/dados'

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: []
        };
        this.getAtleta = this.getAtleta.bind(this);

    }

    getAtleta(user) {
        getUsers(user).then(response => {
            this.setState({ dados: response.data });
        });
    }
    componentDidMount() {
        this.getAtleta(33);
    }
    render() {
        return (
            <div>
                <Header dados={this.state.dados} />
                <SideBar dados={this.state.dados} />
                <Content dados={this.state.dados} />
            </div>
        );
    }
}

export default Admin;