import React, { Component } from "react"
import { MenuNav } from "../App";
import { apiURLAndamento } from '../services/api'

export default class Final extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
            dataJogo: '2021-02-02'
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        await fetch(apiURLAndamento + '2021-02-02')
            .then(resp => resp.json())
            .then(data => this.setState({
                ...this.state,
                datas: data,

            }))
    }

    render() {

        function TabelaJogosAndamento(props) {
            return props.jogosData.map(function (dados, i) {
                var fase = dados.fase
                var hora = dados.hora.split(":")
                let horario = `${hora[0]}:${hora[1]}`
                var item = dados
                var texto
                if (fase === 'final') {
                    texto = <div style={{ color: "#FFD700", fontWeight: 'bolder', fontSize: '40px' }} className="col-md-12">FINAL</div>
                }
                else if (fase === '3lugar') {
                    texto = <div style={{ color: "#b87333", fontWeight: 'bolder', fontSize: '30px' }} className="col-md-12">3º Lugar</div>
                }
                return (
                    <div key={i} className="col-md-12 text-center row mt-2">
                        <div className="col-md-4 text-center"></div>
                        <div className='col-md-4' >
                            {texto}
                            <img width="60px" height="60px" src={item.logoA} alt={item.TimeA} />
                            <span style={{ fontSize: "x-large", fontWeight: "bold", marginRight: "20px", marginLeft: "20px" }}>
                                {horario}
                            </span>
                            <img width="60px" height="60px" src={item.logoB} alt={item.TimeB} />
                        </div >
                        <div className="col-md-4"></div>
                    </div>
                )
            })
        }
        var dataJogo1 = "2021-02-02"
        var date = dataJogo1.split("-")
        var dataJogos = `${date[2]}/${date[1]}/${date[0]}`
        console.log(dataJogo1)
        return (
            <div>
                <MenuNav />
                < div className="content-wrapper " >
                    <div style={{ fontSize: "xx-large", fontWeight: "bold" }} className="col-md-12 text-center">{dataJogos}</div>
                    <TabelaJogosAndamento jogosData={this.state.datas} />
                </ div>
            </div>

        )
    }
}
