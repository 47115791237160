// Header.js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {

    render() {
        const logotipo = this.props.dados.logotipo
        const nome_abrev = this.props.dados.nome;
        const nome = this.props.dados.nome;
        const posicao = ''
        const equipe = this.props.dados.nome;
        const foto = logotipo
        return (
            <nav className="main-header navbar navbar-expand-md navbar-dark navbar-indigo">
                <ul className="navbar-nav">
                    <a href="/" className="navbar-brand">
                        <img src="images/logo.png" alt="copaGospel" className="brand-image img-circle elevation-3"
                        />
                        <span className="brand-text font-weight-light">Copa Gospel</span>
                    </a>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="/" className="nav-link">Inicio</Link>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="/" className="nav-link">Fale Conosco</Link>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown user-menu">
                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <img src={foto} className="user-image img-circle elevation-2" alt="user" />
                            <span className="d-none d-md-inline">{nome_abrev}</span>
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <li className="user-header bg-indigo">
                                <img src={foto} className="img-circle elevation-2" alt="user" />

                                <p>
                                    {nome}
                                    <small>{posicao} - {equipe}</small>
                                </p>
                            </li>
                            <li className="user-body">
                                <div className="row">
                                    <div className="col-4 text-center">
                                        <Link to="#">Ficha</Link>
                                    </div>
                                    <div className="col-4 text-center">
                                        <Link to="#">Equipe</Link>
                                    </div>
                                    <div className="col-4 text-center">
                                        <Link to="#">Estatisticas</Link>
                                    </div>
                                </div>
                            </li>
                            <li className="user-footer">
                                <Link to="#" className="btn btn-default btn-flat">Perfil</Link>
                                <Link to="/Logout" className="btn btn-default btn-flat float-right">Sair</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        )
    }
}