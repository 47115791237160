import React, { Component } from 'react';
import { MenuNav } from '../App';

export default class FotosVideos extends Component {

    render() {
        return (
            <div>
                <MenuNav />
                < div className="content-wrapper " >

                </div >
            </div >
        )
    }
}
