// Content.js

import React, { Component } from 'react';

export default class Content extends Component {
    render() {
        const dados = this.props.dados;
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Estatísticas</h3>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="text-center">
                                                <strong><img alt='equipe' src={dados.logotipo} width="50px" height="50px" /> {dados.equipe}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <div className="row">
                                        <div className=" text-center col-sm-3 col-xs-12">
                                            <img alt='adm' src={dados.foto} width="100px" height="100px" />
                                            <br />
                                            <strong>{dados.nome}</strong>
                                            <br />
                                            <strong>{dados.posicao}</strong>
                                            <br />
                                            <strong>Gols: {dados.gols}</strong>
                                        </div>
                                        <div className="col-sm-12 col-xs-8">
                                            <table id="example" class="table display nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Equipe</th>
                                                        <th>J</th>
                                                        <th>P</th>
                                                        <th>V</th>
                                                        <th>D</th>
                                                        <th>E</th>
                                                        <th>GP</th>
                                                        <th>GC</th>
                                                        <th>SG</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td><img alt='equipe' src={dados.logotipo} width="30px" height="30px" /> {dados.equipe}</td>
                                                        <td>{dados.jogos}</td>
                                                        <td>{dados.pontos}</td>
                                                        <td>{dados.vitoria}</td>
                                                        <td>{dados.derrota}</td>
                                                        <td>{dados.empate}</td>
                                                        <td>{dados.golsPro}</td>
                                                        <td>{dados.golsCont}</td>
                                                        <td>{dados.saldoGols}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        )
    }
}