import React, { Component } from 'react';
import { MenuNav } from '../App'
import { getArtilharia } from '../services/dados'

export default class Artilharia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artilheiros: []
        };
    }
    getArtilharia = async () => {
        await getArtilharia().then(response => {
            this.setState({
                artilheiros: response.data
            });

        });
    }

    componentDidMount() {
        this.getArtilharia()
    }
    render() {
        function Artilharia(props) {
            return props.dados.map(function (dados, i) {
                if (dados.foto === null) {
                    dados.foto = './images/jogadores/semFoto.png'
                }
                if (i <= 2) {
                    return (
                        <div
                            className="top3Artilheiros mt-1"
                            key={dados.Id}
                            style={{
                                border: 'solid black 1px',
                                // backgroundColor: 'green',
                                fontWeight: '500',
                                color: "white"
                            }}
                        >
                            <img
                                className="top3ArtilheirosIMG"
                                src={dados.foto}
                                alt={dados.jogogador}
                            />
                            <br></br>
                            <span className="top3ArtilheirosGols">{dados.gols}</span>
                            <br></br>
                            <img
                                className="top3ArtilheirosIMGEquipe"
                                src={dados.logo}
                                alt={dados.Equipe}
                            />
                            <span className="SpanJogadorTop3">{dados.jogador}</span>
                            <span className="SpanJogadorMobileTop3">{dados.nome_abrev}</span>
                        </div>
                    )
                }
                else {
                    return (
                        <div
                            className="mt-1"
                            key={dados.Id}
                            style={{
                                backgroundColor: i <= 2 ? 'green' : '#4f0030 ',
                                fontWeight: i <= 2 ? '500' : '', color: "white",
                            }}
                        >
                            <img
                                className="Portrait"
                                src={dados.foto}
                                alt={dados.jogogador}
                            />
                            <img
                                className="logotipoEquipe"
                                src={dados.logo}
                                alt={dados.Equipe}
                            />
                            <span className="SpanGols">{dados.gols} Gols</span>
                            <span className="SpanJogador">{dados.jogador}</span>
                            <span className="SpanJogadorMobile">{dados.nome_abrev}</span>
                            <br></br>
                            <span className="SpanEquipe">{dados.Equipe}</span>
                        </div>
                    )
                }
            })
        }
        return (
            <div>
                <MenuNav />
                < div className="content-wrapper " >
                    <Artilharia dados={this.state.artilheiros} />
                </div >
            </div >
        )
    }
}
