// App.js
import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { getClassificacaoA, getClassificacaoB } from './services/dados'

export function MenuNav() {
  return (
    <nav className="main-header navbar navbar-expand navbar-dark navbar-indigo">
      <ul className="navbar-nav">
        <li className="nav-item d-sm-inline-block">
          <Link to="/" className="nav-link">Início</Link>
        </li>
        <li className="nav-item d-sm-inline-block">
          <Link to="/Classificacao" className="nav-link">Classificação</Link>
        </li>
        <li className="nav-item d-sm-inline-block">
          <Link to="/Jogos" className="nav-link">Jogos</Link>
        </li>
        <li className="nav-item d-sm-inline-block">
          <Link to="/Artilharia" className="nav-link">Artilharia</Link>
        </li>
        {/* <li className="nav-item d-sm-inline-block">
          <Link to="/FotosVideos" className="nav-link">Fotos & Videos</Link>
        </li>
        <li className="nav-item d-sm-inline-block">
          <Link to="/Selecao" className="nav-link">Seleção</Link>
        </li> */}
      </ul>
    </nav>
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dadosA: [],
      dadosB: []
    };
    this.getClassifica = this.getClassifica.bind(this);

  }

  getClassifica() {
    getClassificacaoA().then(response => {
      this.setState({
        dadosA: response.data,
      });
    });
    getClassificacaoB().then(response => {
      this.setState({
        dadosB: response.data,
      });
    });
  }
  componentDidMount() {
    this.getClassifica();
  }
  render() {

    function pattern(cell) {
      if (cell == null) {
        return '0%'
      }
      var aprov = cell.split(".")
      return (
        aprov[0] + '%'
      )
    }

    function ClassificacaoA(props) {

      return props.grupoA.map(function (dados, i) {
        let lugar = i + 1 + "º"
        return (
          <tr style={{
            backgroundColor: i <= 3 ? 'green' : 'white ', fontWeight: i <= 3 ? '500' : '',
            color: i <= 3 ? "white" : "Black"
          }} key={i} >
            <td><Link className={i <= 3 ? "linkPage" : "linkPageNot"} to={{
              pathname: `/Equipes/${dados.fktime}`,
              state: { lugar: lugar }
            }}>{lugar}
              <img src={dados.logotipo} width="25px" height="25px" alt='equipe' /> {dados.equipe}</Link></td>
            <td>{dados.pontos}</td>
            <td>{dados.jogos}</td>
            <td>{dados.vitoria}</td>
            <td>{dados.derrota}</td>
            <td>{dados.empate}</td>
            <td>{dados.golsPro}</td>
            <td>{dados.golsCont}</td>
            <td>{dados.saldoGols}</td>
            <td>{pattern(dados.aproveitamento)}</td>
          </tr >
        )
      })
    }
    return (
      <div >
        < MenuNav />
        < div className="content-wrapper" >
          <section className="content-header">
            <div className="row">
              <div className="col-sm">
                <div className="">
                  <div className="box-header with-border">
                    <h3 className="box-title text-center">Classificação</h3>
                  </div>
                  <div className="box-footer">
                    <div className="classGrupos row">
                      <div className="col-sm classGrupos">
                        <div className="col-sm text-center text-bold"> GRUPO A</div>
                        <table key="1" id="example" className="table display nowrap">
                          <thead>
                            <tr style={{ backgroundColor: "#FFF", color: "#000" }}>
                              <th>Equipe</th>
                              <th>P</th>
                              <th>J</th>
                              <th>V</th>
                              <th>D</th>
                              <th>E</th>
                              <th>GP</th>
                              <th>GC</th>
                              <th>SG</th>
                              <th>%</th>
                            </tr>
                          </thead>
                          <tbody key="2">
                            <ClassificacaoA grupoA={this.state.dadosA} />
                          </tbody>
                        </table>
                      </div>
                      <div className="col-sm">
                        <div className="text-center text-bold"> GRUPO B</div>
                        <table key="3" id="example" className="table display nowrap">
                          <thead>
                            <tr style={{ backgroundColor: "#FFF", color: "#000" }}>
                              <th>Equipe</th>
                              <th>P</th>
                              <th>J</th>
                              <th>V</th>
                              <th>D</th>
                              <th>E</th>
                              <th>GP</th>
                              <th>GC</th>
                              <th>SG</th>
                              <th>%</th>
                            </tr>
                          </thead>
                          <tbody key="4">
                            <ClassificacaoA grupoA={this.state.dadosB} />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <section>
                    <div>
                      <div>
                        <span className="faixaLabel">
                          <span className="faixaIcone"></span>
                          Classificados</span>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div >
      </div >
    );
  }
}

export default App;