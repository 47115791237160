import api, { ambiente } from "./../services/api";
import { TOKEN_KEY } from "./auth";
export const token = localStorage.getItem(TOKEN_KEY);

function parseJwt(token) {
  if (!token) {
    return;
  }
  token = token.substring(7);
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export const getUsers = async (user) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(api.get("/jogadores/" + user + "/"));
    }, 200)
  );
};

export const getClassificacaoA = async () => {
  return new Promise(resolve => {
    resolve(api.get("/classificacao/A"))
  }
  );
};
export const getClassificacaoB = async () => {
  return new Promise(resolve => {
    resolve(api.get("/classificacao/B"))
  }
  );
};

export const getDTJogosEncerrados = async () => {
  return new Promise(resolve => {
    resolve(api.get("/jogos/jogosEncerrados/"))
  }
  );
};

export const getDTJogosAndamento = async () => {
  return new Promise(resolve => {
    resolve(api.get("/jogos/jogosAndamento/"))
  }
  );
};

export const getJogosAndamento = async (data) => {
  return new Promise(resolve => {
    resolve(api.get("/jogos/andamento/" + data))
  }
  );
};
export const getJogosEncerrados = async (data) => {
  return new Promise(resolve => {
    resolve(api.get("/jogos/encerrados/" + data))
  }
  );
};

export const getEventoID = async (evento) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(api.get("/eventos/" + evento));
    }, 200)
  );
};

export const getEquipeID = async (equipe) => {
  return new Promise(resolve => {
    resolve(api.get("/equipes/" + equipe))
  }
  );
};

export const getGruposEquipeID = async (equipe) => {
  return new Promise(resolve => {
    resolve(api.get("/equipes/grupos/" + equipe))
  }
  );
};

export const getJogosEncEquipeID = async (equipe) => {
  return new Promise(resolve => {
    resolve(api.get("/equipes/jogosenc/" + equipe))
  }
  );
};

export const getJogosAndEquipeID = async (equipe) => {
  return new Promise(resolve => {
    resolve(api.get("/equipes/jogosand/" + equipe))
  }
  );
};

export const getJogadoresEquipeID = async (equipe) => {
  return new Promise(resolve => {
    resolve(api.get("/equipes/jogadores/" + equipe))
  }
  );
};

export const getEquipes = async () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(api.get("/equipes/"));
    }, 200)
  );
};

export const getArtilharia = async () => {
  return new Promise(resolve =>
    resolve(api.get("/artilharia")));
}

export const getInscricao = async () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(api.get("/inscricoes"));
    }, 200)
  );
};

//functions Uteis
export function ts2human(time) {
  var ts = time;
  var dt = new Date(ts * 1000);
  return (ts, dt.toLocaleString());
}
export function human2ts(data) {
  var di = data;
  var ts = Date.parse(di);

  return (ts / 1000, ts / 1000);
}

//Função que Verifica Autenticação
export const isAuthenticated = () => {
  if (ambiente === 'desenv') {
    return true
  }
  else if (ambiente === 'prod') {
    if (localStorage.getItem(TOKEN_KEY) === null) {
      return false
    } else {
      var userLogged = parseJwt(token).username
      var expira = parseJwt(token).exp
      var data = new Date();
      var dataTS = human2ts(data)
      if (userLogged !== null && expira > dataTS) {
        return true;
      } else {
        return false;
      }
    }
  }
}