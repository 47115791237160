import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Atletas from './Atletas'
import Adm from './Admin'
import Equipes from './Equipes'
import FotosVideos from './components/FotosVideos'
// import Selecao from './Selecao'
import Artilharia from './components/Artilharia'
import Jogos from './components/Jogos'
import { Route, Router } from "react-router-dom";
import { logout } from './services/auth'
import { registerServiceWorker } from './serviceWorker'
import axios from 'axios'
import Final from './components/Final'

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// 

// const renderCartola = () => {
//     ReactDOM.render(<Cartola />, document.getElementById("root"))
// }

const renderReactDom = () => {
    let history = require("history").createHashHistory();
    ReactDOM.render(
        <Router history={history}>
            <Route path="/" exact={true} component={Jogos} />
            <Route path="/Atletas/:id" exact={true} component={Atletas} />
            <Route path="/Admin" exact={true} component={Adm} />
            <Route path="/Jogos" component={Jogos} />
            <Route path="/FotosVideos" component={FotosVideos} />
            <Route path="/Classificacao" component={App} />
            <Route path="/Artilharia" component={Artilharia} />
            <Route path="/Logout" exact={true} component={logout} />
            <Route exact path={"/Equipes/:id"} component={Equipes} />
        </Router>,
        document.getElementById("root")
    );
};
const onDeviceReady = () => {
    var model = window.device.model
    var plataform = window.device.version
    window.FirebasePlugin.getToken(function (token) {
        var params = {
            token: token,
            model: model,
            plataform: plataform
        }
        let res = axios.post('https://api.gasistemasweb.com.br/notification', params);
        console.log(res.data)

    }, function (error) {
        console.error(error);
    });
    renderReactDom();
}
// serviceWorker.unregister();
if (window.cordova) {
    document.addEventListener('deviceready', () => {
        onDeviceReady();

    }, false);
} else {
    renderReactDom();
    // renderCartola()
}

registerServiceWorker();
