import React, { Component } from 'react';
import { MenuNav } from '../App'
import { getDTJogosAndamento, getDTJogosEncerrados } from '../services/dados'
import { JogosAndamento, JogosEncerrados } from './jogosAndamento'
import PropTypes from 'prop-types';

import { makeStyles, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
// import  from '@material-ui/core/AppBar';
// import  from '@material-ui/core/Tabs';
// import  from '@material-ui/core/Tab';
// import  from '@material-ui/core/Typography';
// import  from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function JogosAndamentoDate(props) {
    return props.jogos.map(function (data, a) {
        var dataUS = data.data
        var rodada = data.rodada
        var date = dataUS.split("-")
        var dataJogos = `${date[2]}/${date[1]}/${date[0]}`
        var texto
        if (rodada > 8) { texto = "" }
        else { texto = rodada + " º Rodada" }
        return (
            <div key={a}>
                <div style={{ fontSize: "xx-large", fontWeight: "bold" }} className="text-center mb-4 mt-4">
                    {dataJogos}<br />{texto}
                </div>
                <JogosAndamento dataJogos={data.data} />
            </div>
        )

    })
}

function JogosEncerradosDate(props) {

    return props.jogos.map(function (data, a) {
        var dataUS = data.data
        var rodada = data.rodada
        var date = dataUS.split("-")
        var dataJogos = `${date[2]}/${date[1]}/${date[0]}`
        return (
            <div key={a}>
                <div style={{ fontSize: "xx-large", fontWeight: "bold" }} className="text-center mb-4 mt-4">
                    {dataJogos}<br />{rodada}º Rodada
                </div>
                <JogosEncerrados dataJogos={data.data} />
            </div>
        )

    })
}

function NavTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab label="Proximos Jogos" href="/drafts" {...a11yProps(0)} />
                    <LinkTab label="Jogos Anteriores" href="/trash" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <JogosAndamentoDate jogos={props.JogosAnd} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <JogosEncerradosDate jogos={props.JogosEnc} />
            </TabPanel>
        </div>
    );
}


export default class Jogos extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            jogosAndamento: [],
            jogosEncerrados: [],
        };
        this.getJogosApi = this.getJogosApi.bind(this);
    }

    getJogosApi() {
        getDTJogosAndamento().then(response => {
            this.setState({ jogosAndamento: response.data });
        });
        getDTJogosEncerrados().then(response => {
            this.setState({ jogosEncerrados: response.data });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.getJogosApi();
        }
    }
    render() {
        return (
            <div>
                <MenuNav />
                < div className="content-wrapper " >
                    <NavTabs JogosAnd={this.state.jogosAndamento} JogosEnc={this.state.jogosEncerrados} />
                </div >
            </div >
        )
    }
}