import { Redirect } from "react-router-dom";
import React from "react";

export const TOKEN_KEY = "CopaGospel";

export const logout = () => {
  return (
    <Redirect
      to={{ pathname: "/" }
      }
    />
  )
};
